.reg-con {
  max-width: 700px;
  margin: 20px auto;
  padding: 30px;
  background: linear-gradient(135deg, #e0eec8, rgb(204, 237, 245));
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.reg-con h1 {
  text-align: center;
  margin-bottom: 30px;
}

.reg-p {
  color: black;
  font-size: 24px;
}

.reg-gender {
  color: black;
}

.form-group {
  margin-bottom: 30px;
}

/* Label styling */
label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: black;
}

/* Input and textarea styling */
input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
input[type="file"],
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus,
input[type="file"]:focus,
select:focus {
  outline: none;
  /* width: 725px; */
}

textarea {
  min-height: 100px;
  resize: vertical; /* Allow vertical resizing */
}

.radio-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Individual radio button */
.radio-group input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #bacbbb; /* Border color */
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Radio button checked state */
.radio-group input[type="radio"]:checked {
  background-color: grey; /* Fill color when checked */
  border-color: #4CAF50; /* Border color when checked */
}

/* Radio button label */
.radio-group label {
  color: #555555;
  cursor: pointer;
}

/* Button container styling */
.button-container {
  text-align: center;
  margin-top: 30px;
}

/* Submit button styling */
.btn-send {
  padding: 14px 28px;
  background-color: #bae973;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-send:hover {
  background-color: #45a049;
}

/* File input styling */
input[type="file"] {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 16px;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .reg-con {
    padding: 20px;
  }

  .reg-con h2 {
    font-size: 22px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  input[type="file"],
  select {
    padding: 10px;
    font-size: 14px;
  }
}
select{
  width: 725px;
}

.required {
  color: red;
}
