.big-container2 {
    display: flex;
    padding: 90px;
    justify-content: space-around;
    align-items: flex-start;
    padding: 90px;
    gap: 50px;
    background-color:white;
  }
  
  
  
  .webright{
    width: 70%;
    padding: 20px;
    border: 1px solid #5ed7d1;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   max-height: 100vh;
   overflow-y: auto;
  }
  
  .curriculumh1 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
     color:#0c191c;
     
  }
  
  .highlight {
    color:#e5da13;
  }
  
 
  .curriculum-header1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    transition: background-color 0.3s ease;
    
   
  }
  .submodule-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    padding-left: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    margin-right: 30px;
    transition: background-color 0.3s ease;
  }
  
  .curriculum-header:hover {
    background-color: #f1f1f1;
    
  }
  
  .curr-font {
  font-family: Arial, Helvetica, sans-serif;
  
  
  }
  
  .plus-icon {
    padding-right: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .rotate {
    transform: rotate(45deg);
  }
  
  .curriculum-content {
    display: none;
    padding: 15px;
    border-left: 3px solid #ddd;
    margin-left: 15px;
    animation: fadeIn 0.3s ease;
    background-color:white;
    opacity: 0.7;
    border: 1px solid #5ed7d1;
  }
  .submodule-content{
    display: none;
    padding: 15px;
    border-left: 3px solid #ddd;
    margin-left: 15px;
    animation: fadeIn 0.3s ease;
    background-color:white;
    opacity: 0.9;
    border: 1px solid #5ed7d1;
  }
  .curriculum-content.show {
    display: block;
    border-radius: 20px;
    margin-left: 50px;
    margin-bottom: 20px;
  }
  .submodule-content.show{
    display: block;
    border-radius: 20px;
    margin-left: 50px;
    margin-bottom: 20px;
  }
  .curriculum-checkbox {
    width: 30px;
    height: 30px;
    background-color: #1d5863;
    border: 2px solid #fff;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    color: #1d5863;
  }
  .curriculum-checkbox :checked{
    background-color:#1d5863;
    border-color: #1d5863;
  }
  
  .curriculum-checkbox ::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #1d5863;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
    border-radius: 2px;
  }
  .curriculum-checkbox:checked + .curriculum-checkbox ::after {
    transform: translate(-50%, -50%) scale(1);
  }
  
/* Add your styles for showing and hiding content */
.curriculum-content, .submodule-content {
    display: none;
}

.curriculum-content.show, .submodule-content.show {
    display: block;
}

.plus-icon {
    transition: transform 0.3s ease;
}

.plus-icon.rotate {
    transform: rotate(45deg);
}

/* Add any additional styles as needed */

  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  