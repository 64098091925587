body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
 
}


.navbar-logo img {
  height: 50px;
}

.navbarlinks {
  display: flex;
  gap: 30px; /* Adjusted the gap */
  margin-left: auto;
}

.nav-item, .nav-registration, .nav-code {
  color: black;
  text-decoration: none;
  padding: 10px;
  position: relative;
  cursor: pointer;
}

.nav-item:hover, .nav-registration:hover, .nav-code:hover {
  border-bottom: 1px solid #a4e049;
}

.dropdown {
  position: relative;
  margin-top: 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1d5863;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 100%;
  left: 0;
  border-radius: 4px;
}

.dropdown-content .dropdown-item {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  cursor: pointer;
}

.dropdown-content .dropdown-item:hover {
  border-bottom: 1px solid #a4e049;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-item:after, .nav-registration:after, .nav-code:after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #a4e049;
  transition: width 0.3s;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
}

.nav-item:hover:after, .nav-registration:hover:after, .nav-code:hover:after {
  width: 100%;
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .navbar-logo img {
    height: 40px;
  }

  .navbarlinks {
    flex-direction: column;
    gap: 10px;
    margin-left: 0;
    margin-top: 10px;
  }

  .nav-item,
  .nav-registration,
  .nav-code {
    padding: 5px;
  }

  .dropdown {
    margin-top: 5px;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    min-width: auto;
  }

  .dropdown-content .dropdown-item {
    padding: 8px 12px;
  }
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  margin-left: auto;
}

@media (max-width: 768px) {
  .navbarlinks {
    display: none;
  }

  .navbarlinks.show {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .menu-icon {
    display: block;
  }
}
