.course-ad-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  animation: fadeIn 0.5s ease-in-out;
}

.course-ad {
  background:  #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  position: relative;
  width: 55%;
  
}

.course-ad h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

.course-ad p {
  font-size: 16px;
  color: #555;
  margin-bottom: 24px;
  text-align: center;
}

.course-ad button {
  background-color: #7bbc42;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}



.course-ad .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color:#7bbc42;
  color:black;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.course-ad .close-btn:hover {
  background-color: #aaa;
}
.coursead{
  display: flex;
flex-direction: column;
 border-radius: 20px;
  height: 580px;
  width: 450px;
  margin-left: 26%;
  line-height: 1.1;
  box-shadow: 10px 0 10px 0 #1d5863;
}
.coursead h2{
  font-size: 28px;

  margin:20px ;
}
.coursead p{
  margin-left: 20px;
  margin-right: 20px;

  text-align: justify;
}
.courseadbutton{
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  border-radius: 25px;
  margin-top: 10px;
  margin-left: 250px;
  background:#bae973;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
