.aboutheader {
    color: #141313;
    text-align: center;
    padding: 20px 0;
}

.aboutsection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin: auto;
}

.aboutsection img {
    max-width: 100%;
    height: auto;
}

.aboutcontent {
    padding: 25px;
    text-align: justify;
}

.aboutcontent p {
    color: #666;
    font-size: 18px;
    line-height: 1.5;
    margin: 15px 0;
}

.aboutflexbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.aboutflex {
    flex: 1;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin: 10px 0;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    height:40vh;
}

.aboutflex:hover {
    background-color: #e6f2cf;
}

/* Media Queries */
@media (min-width: 768px) {
    .aboutsection {
        flex-direction: row;
    }

    .aboutsection img {
        flex: 0 35%;
        max-width: 35%;
    }

    .aboutflexbox {
        flex-direction: row;
        justify-content: space-between;
    }

    .aboutflex {
        width: 30%;
        margin: 0 10px;
    }
}
