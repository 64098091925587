.coding{
    margin: 0;
}
.codingh1{
    text-align: center;
}
.codingp{
  text-align: center;
   font-size: 18px;
   opacity: 0.7;
}

.code-masters {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
  }
  
  .codemastersh1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .codemastersp {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
  }
  
  .codemastersh2 {
    font-size: 1.5em;
    color: #222;
    margin-top: 20px;
  }
  
  .codemastersul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .codemastersli {
    margin-bottom: 10px;
    font-size: 1em;
    color: #444;
  }
  
  .code-masters a {
    color: #007bff;
    text-decoration: none;
  }
  
  .code-masters a:hover {
    text-decoration: underline;
  }
  .codingflex{
    display: flex;
    gap: 25px;
    margin-bottom: 50px;
  }
  .codingflexbox {
    flex-direction: column;
    border: 2px solid black;
    height: 500px;
    width: 30%;
    margin-left: 20px;
  }
  .codingflexbox img{
    width: 100%;
    height: 100%;
  }