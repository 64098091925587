.address-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 20px;
  background-color: #1d5863;
  border-bottom: 1px solid #ddd;
}

.address-bar .left,
.address-bar .right {
  display: flex;
  align-items: center;
  color: white;
  flex-wrap: wrap;
}

.address-bar .left span,
.address-bar .right .social-icon {
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: white;
}

.address-bar .right .number {
  margin-right: 20px;
  color: white;
}

.address-bar .social-icon {
  text-decoration: none;
  color: white;
  font-size: 20px;
}

.address-bar .social-icon:hover {
  color: white;
}

.address-bar .left .email a {
  color: white;
  text-decoration: none;
}

.address-bar .left .email a:hover {
  color: #ccc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .address-bar {
    flex-direction: column;
    align-items: center;
  }
  .address-bar .left, 
  .address-bar .right {
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .address-bar .left span, 
  .address-bar .right .social-icon, 
  .address-bar .right .number {
    margin-right: 10px;
    font-size: 14px;
  }
  .address-bar .social-icon {
    font-size: 18px;
  }
}
