.webcontainer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
}

.left-section h1,
p {
  text-align: left;
}

.left-section {
  flex: 1;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Heading1 {
  font-size: 2rem;
  color: #333;
  margin-top: 0;
  margin-left: 70px;
}

.Heading2 {
  font-size: 1.5rem;
  color: #666;
  margin-top: 10px;
  margin-left: 70px;
}

.rating {
  color: #f0ad4e;
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-left: 70px;
}

.Details {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin-left: 70px;
  letter-spacing: 0.6px;
  line-height: 1.9em;
}

.points {
  margin-left: 60px;
  letter-spacing: 0.6px;
  line-height: 1.8em;
}

.icons-section {
  display: flex;
  flex-direction: rows;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.icon-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.icon-item i {
  font-size: 2rem;
  color: #053e56;
  margin-right: 10px;
}

.icon-item p {
  font-size: 1rem;
  color: #333;
  font-size: larger;
  font-family: sans-serif;
}

.course-imageweb img {
  margin-top: 20px;
  max-width: 90%;
  height: auto;
  border-radius: 8px;
  margin-left: 10px;
}

.buttons1 {
  margin-top: 20px;
  margin-top: 150px;
}

.buttons1 .btn1 {
  display: inline-block;
  padding: 15px 30px;
  margin-right: 10px;
  font-size: 1.5rem;
  color: black;
  background: linear-gradient(90deg, #b7e650, #addc8f);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.buttons1 .btn1:hover {
  background: linear-gradient(90deg, #d3dc4a, #9be284);
  box-shadow: 0 6px 8px rgba(183, 219, 106, 0.15);
  transform: translateY(-2px);
}

.buttons1 .btn1:active {
  background: linear-gradient(90deg, #9be284, #d3dc4a);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}
@media (max-width: 1200px) {
  .webcontainer {
    flex-direction: column;
    padding: 20px;
  }

  .left-section {
    flex: auto;
    margin-bottom: 20px;
  }

  .right-section {
    flex: auto;
  }

  .Heading1 {
    font-size: 1.8rem;
    margin-left: 0;
  }

  .Heading2 {
    font-size: 1.3rem;
    margin-left: 0;
  }

  .rating {
    font-size: 1.3rem;
    margin-left: 0;
  }

  .Details {
    font-size: 0.9rem;
    margin-left: 0;
  }

  .points {
    margin-left: 0;
  }

  .icon-item i {
    font-size: 1.5rem;
  }

  .icon-item p {
    font-size: 0.9rem;
  }

  .course-imageweb img {
    max-width: 100%;
    margin-left: 0;
  }

  .buttons1 {
    margin-top: 20px;
  }

  .buttons1 .btn1 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .Heading1 {
    font-size: 1.6rem;
  }

  .Heading2 {
    font-size: 1.1rem;
  }

  .rating {
    font-size: 1.1rem;
  }

  .Details {
    font-size: 0.8rem;
  }

  .icon-item i {
    font-size: 1.2rem;
  }

  .icon-item p {
    font-size: 0.8rem;
  }

  .buttons1 .btn1 {
    font-size: 1rem;
    padding: 12px 24px;
  }
}

@media (max-width: 480px) {
  .Heading1 {
    font-size: 1.4rem;
  }

  .Heading2 {
    font-size: 1rem;
  }

  .rating {
    font-size: 1rem;
  }

  .Details {
    font-size: 0.7rem;
  }

  .icon-item i {
    font-size: 1rem;
  }

  .icon-item p {
    font-size: 0.7rem;
  }

  .buttons1 .btn1 {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}
