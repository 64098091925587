.footer {
  background-color: #7A7A7A;
  color: #fff;
}

.footer-addresses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-address {
  flex: 1;
  margin: 10px 20px;
  min-width: 250px; /* Ensure each address has a minimum width */
}

.footer-address h3 {
  margin-bottom: 10px;
  text-decoration: underline solid white;
}

.footer-address p {
  line-height: 1.6;
  text-align: left;
}

.footer-copyright {
  background-color: #1d5863;
  text-align: center;

  padding: 10px 0;
}

.copyright-content {
  font-size: 16px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer-addresses {
    flex-direction: column;
    align-items: center;
  }
  .footer-address {
    margin: 10px 0;
    text-align: center;
  }
  .footer-address h3 {
    font-size: 16px;
  }
  .footer-address p {
    font-size: 14px;
  }
}
