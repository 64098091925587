.advertisement-container2 {
  display: flex;
  justify-content: right;
  align-items: right;
  padding:10px;
  border-radius: 10px;
  position: absolute;
  top: 250px; 
  right: 15px;
  width: 20%; 
  height: 75hv;
}

.advertisement-content2 {
  display: flex;
  position: relative; 
}

.advertisement-image2 {
  width: 100%; 
  height: 75vh;
 margin-left: 10px; 
}

.close-button2 {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: white; 
}

.close-button2::before {
  content: "\2715";
}